<template>
  <v-menu offset-y min-width="380px" v-model="menu" :close-on-content-click="false">
    <template v-slot:activator="{on, attrs}">
      <v-btn v-bind="attrs" color="secondary" class="ml-2 mt-n2" v-on="on">
        GROUPS TEMPLATES
      </v-btn>
    </template>
    <div class="grey lighten-4 pa-4">
      <div v-for="(item, index) in templates" :key="index" class="title-hover" >
        <div class="d-flex flex-row " >
          <div class="py-1 grey--text text--darken-2">{{ item.title }}</div>
          <div class="ml-auto">
            <v-tooltip  bottom color="primary  lighten-1">
              <template v-slot:activator="{on, attrs}">
                <v-btn color="primary" v-bind="attrs" v-on="on" icon small @click="applyTemplate(item.id, item.title)" ><v-icon small>mdi-tray-arrow-down</v-icon> </v-btn>
              </template>
                <span>Apply {{item.title}} to {{datasetName}}</span>
            </v-tooltip>
            <v-tooltip  bottom color="secondary lighten-1" >
              <template v-slot:activator="{on, attrs}">
                <v-btn color="secondary" v-bind="attrs" v-on="on" icon small @click="fillTemplate(item.id, item.title)" ><v-icon small>mdi-tray-arrow-up</v-icon> </v-btn>
              </template>
                <span>Fill {{item.title}} from {{datasetName}}</span>
            </v-tooltip>
            <v-tooltip bottom color="grey">
              <template v-slot:activator="{on, attrs}">
            <v-btn v-bind="attrs" v-on="on" icon small @click="removeTemplate(item.id)" ><v-icon small >mdi-trash-can-outline</v-icon> </v-btn>
              </template>
                <span>Delete {{item.title}} </span>
            </v-tooltip>
          </div>
        </div>
      <v-divider></v-divider>
      </div>
      <div v-if="showNewTemplate" class="d-flex flex-row mb-n3">
          <v-text-field height="20px" label="New Template"   v-model="newTemplate"></v-text-field>
          <v-btn icon class="mt-2" color="primary" @click="saveNewTemplate"><v-icon small>mdi-check</v-icon> </v-btn>
            <v-btn class="mt-2" icon  @click="showNewTemplate = false" ><v-icon small >mdi-close</v-icon> </v-btn>

      </div>
      <div class="d-flex flex-row mt-2">
        <v-spacer></v-spacer>
          <v-btn x-small outlined color="primary" @click="closeTemplate">CLOSE </v-btn>
          <v-btn class="ml-1" x-small outlined color="primary" @click="showNewTemplate = true">NEW TEMPLATE </v-btn>

      </div>

    </div>

  </v-menu>
</template>
<script>
import {getTemplates, addTemplate, deleteTemplate} from '@/api/dataset'
export default {
  name: 'FieldGroupTemplate',
  props:{
    datasetName: {
      type: String,
      default: 'dataset'
    }
  },
  data(){
    return {
      menu: false,
      showNewTemplate: false,
      templates: [],
      newTemplate: null,
    }
  },
  created(){
    this.loadTemplates()
  },
  methods:{
    loadTemplates(){
      const _this = this
      getTemplates(res => _this.templates = res)
    },
    saveNewTemplate(){
      const _this = this
      addTemplate(this.newTemplate, () => {_this.newTemplate = null; _this.loadTemplates()}, 'Template added successfully' )
      this.showNewTemplate  =false
    },
    removeTemplate(id){
      const _this = this
      deleteTemplate(id, ()=> {_this.loadTemplates()} , 'Template deleted successfully')
    },
    applyTemplate(id, templateName){
      this.$emit('onApplyTemplate', id, templateName)
    },
    fillTemplate(id, templateName){
      this.$emit('onFillTemplate', id, templateName)
    },
    closeTemplate(){
      this.menu = false
    }
  }
}
</script>
<style scoped>
.title-hover:hover{
  background-color: #EEEEEE !important;
}
</style>